import React from 'react'
import * as Styled from './Certificates.styled'

import { Container } from '@/theme/Grid.styled'
import { Certificates as CertificatesGallery } from '@/components/Gallery/gallery.constants'
import Gallery from '@/components/Gallery/Gallery'
import { Bolder, Header2, Paragraph } from '@/theme/Typography.styled'

interface Props {
  showList?: Boolean
}

const Certificates = ({ showList = false }: Props) => (
  <Styled.Description>
    <Container>
      <Header2 marginBottom={39}>
        <Bolder>Certyfikaty</Bolder>
      </Header2>

      {showList && (
        <div style={{ marginBottom: `30px`, textAlign: `left` }}>
          <Paragraph>
            31.07.2023 - Kurs Wojtków Szkolenia{` `}
            <strong style={{ textTransform: `uppercase` }}>
              "To moje! Nie oddam! Jak pracować z problemem obrony zasobów?
              Kulisy pracy behawiorystów"
            </strong>
          </Paragraph>

          <Paragraph>
            04.2023 - Szkolenie prowadzone przez Annę Redlicką{` `}
            <strong>„ETYKA I PRAKTYKA W HODOWLI PSÓW ZKwP”</strong>
          </Paragraph>

          <Paragraph>
            07.2022 - Webinar{` `}
            <strong>„LĘK SEPARACYJNY Z MALENĄ DE MARTINI”</strong>
          </Paragraph>

          <Paragraph>
            06.2022 - Warsztaty prowadzone przez Katarzynę Wawryniuk{` `}
            <strong>
              „ANALIZA, DIAGNOSTYKA I PROCEDURY SZKOLENIA PSA REAKTYWNEGO
              WYKAZUJĄCEGO ZACHOWANIA AGRESYWNE ORAZ TERAPIA BEHAWIORALNA WE
              WSPÓŁPRACY Z OPIEKUNAMI ZWIERZĘCIA - UJĘCIE PRAKTYCZNE”
            </strong>
          </Paragraph>

          <Paragraph>
            05.2022 - Warsztaty prowadzone przez Paulinę Ziółkowską{` `}
            <strong>
              „AGRESJA I AGRESYWNOŚĆ W PRAKTYCE. AGRESYWNA KOMUNIKACJA I
              ZACHOWANIA NIEBEZPIECZNE - JAK SKUTECZNIE PRACOWAĆ Z PSEM
              PREZENTUJĄCYM TAKIE ZACHOWANIA?"
            </strong>
          </Paragraph>

          <Paragraph>
            05.2022 - XII Ogólnopolska Konferencja Behawioryzmu Zwierząt{` `}
            <strong>
              „AGRESJA I REAKTYWNOŚĆ PSÓW - JAK PROWADZIĆ TERAPIĘ BEHAWIORALNĄ
              NA PODSTAWIE NAJNOWSZYCH BADAŃ?”
            </strong>
          </Paragraph>

          <Paragraph>
            04.2022 - Kurs Online Małgorzaty Biegańskiej - Hendryk{` `}
            <strong>„OPIEKA NAD KOCIĄ RODZINĄ”</strong>
          </Paragraph>

          <Paragraph>
            12.2020 - Webinar Piotra Wojtków i Zo i Zaniewskiej - Wojtków{` `}
            <strong>„OCENA PSÓW AGRESYWNYCH”</strong>
          </Paragraph>

          <Paragraph>
            01.2021 - Webinarium Angeli Stockdale{` `}
            <strong>„NATURALNA KOMUNIKACJA U PSÓW”</strong> o uczeniu się o
            psach od psów i o tym jak rodziły się koncepcje Teaching Doga i Klas
            Komunikacji
          </Paragraph>

          <Paragraph>
            08.2022 - Szkolenie Hempets w zakresie{` `}
            <strong>
              stosowania CBD (kannabidiolu) w medycynie weterynaryjnej zwierząt
              towarzyszących
            </strong>
          </Paragraph>
        </div>
      )}

      <Gallery columns={5} items={CertificatesGallery} />
    </Container>
  </Styled.Description>
)

export default Certificates
