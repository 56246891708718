import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 50px auto 50px;
`
const IconFootPrintCat = () => (
  <Wrapper>
    <svg
      width="79"
      height="69"
      viewBox="0 0 79 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.437 19.8645C50.43 22.0365 43.8006 19.5517 40.6308 14.3084C37.461 9.06507 38.9417 3.04783 43.9487 0.874454C48.9557 -1.29892 55.5914 1.18728 58.7692 6.43195C61.9469 11.6766 60.444 17.6911 55.437 19.8645Z"
        fill="#30D5C8"
      />
      <path
        d="M30.5248 23.7818C26.4962 26.308 20.0396 25.1534 16.1032 21.2018C12.1668 17.2501 12.2316 12.0013 16.2651 9.47386C20.2986 6.94642 26.7503 8.0997 30.6883 12.0527C34.6263 16.0056 34.5535 21.2557 30.5248 23.7818Z"
        fill="#30D5C8"
      />
      <path
        d="M20.5764 37.5758C19.6726 41.4105 14.4385 43.7438 8.87146 42.7851C3.30442 41.8264 -0.458123 37.9459 0.444143 34.1191C1.34641 30.2922 6.58202 27.9551 12.1506 28.9098C17.7192 29.8646 21.4802 33.7477 20.5764 37.5758Z"
        fill="#30D5C8"
      />
      <path
        d="M9.93044 59.1809C7.67977 56.1307 9.23838 51.6212 13.4104 49.1082C17.5824 46.5952 22.794 47.0199 25.0462 50.0674C27.2983 53.1148 25.7443 57.6243 21.5723 60.1373C17.4003 62.6503 12.1841 62.2228 9.93044 59.1809Z"
        fill="#30D5C8"
      />
      <path
        d="M43.8191 68.0193C50.7872 67.7251 55.1026 61.3285 57.2958 56.4667C58.9143 52.888 59.9801 49.4021 62.8634 46.331C66.9388 41.9915 73.9337 39.6787 77.3586 34.9322C80.616 30.4112 77.398 26.0386 73.0258 23.0895C67.3019 19.2338 61.7217 23.6329 56.08 25.0776C52.4862 25.9962 48.6461 25.841 44.9591 25.458C41.4569 25.0961 37.1162 24.3698 33.6898 25.9882C24.8695 30.1487 28.6939 38.218 31.6197 44.1467C33.1198 47.182 33.2335 50.0502 32.725 53.279C31.7619 59.4542 34.1698 68.4249 43.8191 68.0193Z"
        fill="#30D5C8"
      />
    </svg>
  </Wrapper>
)

export default IconFootPrintCat
