import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'

import { Bolder, Header2, Paragraph } from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'
import IconFootPrintCat from '@/components/IconFootPrintCat/IconFootPrintCat'

import Image1 from '@/assets/images/about-me-1.png'
import Image2 from '@/assets/images/about-me-2.png'
import Certificates from '@/components/Certificates/Certificates'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  text-align: center;
  padding: 30px 0;

  img {
    width: 600px;
    max-width: 90%;
    margin: 30px auto;
  }
`

const Description = styled.div`
  padding: 50px 0;
`

const Head = () => (
  <>
    <title>
      Pet School - Szkolenia i terapia behawioralna zwierząt | O mnie
    </title>
  </>
)

const AboutMePage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <img alt="Pet School - behawiorysta Wrocław" src={Image1} />

          <Container>
            <Description>
              <Header2 marginBottom={39}>
                <Bolder>O mnie</Bolder>
              </Header2>

              <Paragraph>
                Jestem dyplomowanym behawiorystą zwierząt towarzyszących oraz
                ukończyłam kurs trenerski psów certyfikowany dyplomem MEN.
                Posiadam tytuł magistra biologii, w trakcie studiów zajmowałam
                się etologią zwierząt dzikich. Na co dzień pracuję jako technik
                weterynarii w jednej z wrocławskich klinik.
              </Paragraph>

              <IconFootPrintCat />

              <Paragraph>
                W moim rodzinnym domu zwierzęta były od zawsze. Od najmłodszych
                lat wiedziałam, że chcę pracować ze zwierzętami. W ten sposób w
                2012 roku ukończyłam Technikum Weterynaryjne w Jeleniej Górze.
                {` `}
              </Paragraph>

              <Paragraph>
                Po ukończeniu szkoły postanowiłam pogłębić swoją wiedzę o
                zwierzętach również tych nieudomowionych. Rozpoczęłam studia na
                Uniwersytecie Wrocławskim na kierunku biologia o specjalizacji
                środowisko. Najbardziej fascynowała mnie etologia zwierząt więc
                w&nbsp;swojej pracy magisterskiej badałam budżet aktywności foki
                pospolitej w niewoli, a dokładniej we Wrocławskim Ogrodzie
                Zoologicznym. W trakcie studiów również odbyłam praktyki w
                Ogrodzie Zoologicznym, gdzie zajmowałam się zwierzętami w sekcji
                małych ssaków. Działałam czynnie w Kole Naukowym Etologów, gdzie
                brałam udział w różnych projektach badawczych.
              </Paragraph>

              <Paragraph>
                Po studiach magisterskich zdecydowałam się na studia podyplomowe
                na kierunku Behawiorystyka Zwierząt Towarzyszących w Wyższej
                Szkole Europejskiej w Krakowie. Ukończyłam kurs trenerski psów,
                certyfikowany dyplomem MEN prowadzony przez Zulę Przybylińską
                w&nbsp;szkole Qumam w Krakowie. Stale poszerzam swoją wiedzę
                biorąc udział w licznych szkoleniach, warsztatach i&nbsp;
                konferencjach.
              </Paragraph>
            </Description>
          </Container>
        </Wrapper>

        <Certificates showList={true} />

        <Wrapper>
          <Container>
            <Description>
              <img alt="Pet School - szkolenia psów" src={Image2} />

              <Paragraph>
                Prywatnie jestem właścicielką dwóch psów rasy Welsh Corgi
                Pembroke. To właśnie dzięki uczęszczaniu z pierwszym z nich,
                Bogo, na liczne szkolenia i wystawy, postanowiłam zająć się
                zawodowo behawioryzmem zwierząt. Na co dzień ćwiczymy
                posłuszeństwo, nosework oraz uczymy się nowych sztuczek. Po
                trzech latach dołączyła do nas suczka Cherry, z którą trenuję
                obedience, pasienie owiec oraz uczestniczymy w wystawach psów
                rasowych. Interesuję się również terrarystyką. Obecnie posiadam
                dwa gady - kameleona jemeńskiego oraz gekona lamparciego. Na co
                dzień oprócz behawiorystyki i szkolenia psów pracuję w jednej z
                Wrocławskich klinik całodobowych jako technik weterynarii.
              </Paragraph>
            </Description>
          </Container>
        </Wrapper>
      </Layout>
    </>
  )
}

export default AboutMePage
